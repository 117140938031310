import http from "@/utils/request";

export function getList(params) {
    return http({
        url: '/unifyWarehouseReturn/unifyWarehouseReturnList.htm',
        method: 'get',
        params
    })
}

export function getExport(params) {
    return http({
        url: '/unifyWarehouseReturn/exportData.nd',
        method: 'get',
        params,
        responseType: 'blob'
    })
}