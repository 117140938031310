import {
    getList,
    getExport
} from './api'
export default {
    name: 'inspection',
    data() {
        return {

            breadcrumbData: [{
                    path: "/index",
                    name: "index",
                    title: "首页"
                },
                {
                    path: "/center/returnGoods",
                    name: "returnGoods",
                    title: "共享退货明细"
                }
            ],
            hideOrShowSize: true,
            showTotalColumns: ['单价', '下单数量', '金额', '签收数量'],
            columns: [{
                    label: '销售组织',
                    prop: 'orgName',
                    width: 100,
                },
                {
                    label: '退货单号',
                    prop: 'returnOrderCode',
                    width: 120,
                },
                {
                    label: '退货商家编码',
                    prop: 'returnCustCode',
                    width: 100,
                },
                {
                    label: '退货商家名称',
                    prop: 'returnCustName',
                    width: 120,
                },
                {
                    label: '退货统仓',
                    prop: 'returnWarehouse',
                    width: 100,
                },
                {
                    label: '型号',
                    prop: 'zzprdmodel',
                    width: 100,
                },
                {
                    label: '质量等级',
                    prop: 'batch',
                    width: 100,
                },
                {
                    label: '单价',
                    prop: 'billPrice',
                    align: 'right',
                    width: 100,
                },

                {
                    label: '下单数量',
                    prop: 'erpQty',
                    width: 100,
                    align: 'right'
                },
                {
                    label: '金额',
                    prop: 'xj',
                    width: 100,
                    align: 'right'
                },
                {
                    label: '签收数量',
                    prop: 'signQty',
                    width: 100,
                    align: 'right'
                },

                {
                    label: '状态',
                    prop: 'status',
                    width: 100,
                },
                {
                    label: '创建日期',
                    prop: 'createdDate',
                    width: 100,
                },
            ],
            tableData: [],
            pager: {
                pageNo: 1,
                pageSize: 10,
                count: 10
            },
            ssearchList: {
                storeCode: '',
                storeName: '',
                submiterCode: '',
                submiterName: '',
                org3name: '',
                startTime: '',
                pageNo: 1,
                pageSize: 10
            },
            searchNumber: 0
        }
    },
    mounted() {
        this.tablelist();
    },
    beforeCreate() {
        // 添加背景色
        document.querySelector('body').setAttribute('style', 'background-color:#ffffff')

    },
    beforeDestroy() {
        document.querySelector('body').setAttribute('style', '')
    },
    methods: {
        exportData() {
            this.isLoading = true;
            let data = this.ssearchList;
            getExport(data).then(res => {
                let reader = new FileReader();
                reader.readAsText(res.data);
                reader.onload = (event) => {
                    try {
                        var content = JSON.parse(reader.result);
                        if (content && content.code == 'success') {
                            this.$message.success('下载成功，请到下载中心查看!')
                        } else {
                            this.downloadFile(res.data, '统仓退货.xls')
                        }
                    } catch (err) {
                        console.log(err)
                        this.downloadFile(res.data, '统仓退货.xls')
                    }
                    this.expLoading = false;
                };
            })
        },
        downloadFile(params) {
            var blob = params;
            if (
                "download" in document.createElement("a") &&
                navigator.userAgent.indexOf("Edge") == -1
            ) {
                var elink = document.createElement("a");
                elink.download = "统仓退货.xls";
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            } else {
                navigator.msSaveBlob(blob, "统仓退货.xls");
            }
        },
        inputSearch(data) {
            this.ssearchList = data[0];
            // this.searchzz = true;
            this.tablelist();
        },
        tablelist() {
            let data = this.ssearchList;
            data.pageSize = this.pager.pageSize;
            data.pageNo = this.pager.pageNo;
            getList(data).then(res => {
                this.tableData = res.data.list;
                this.pager.count = res.data.totalCount;
                this.searchNumber = res.data.totalCount;
            })
        },
        hideOrShow(data) {
            this.hideOrShowSize = data;
        },
        pageSearch(pager) {
            this.pager.pageNo = pager.pageNo;
            this.pager.pageSize = pager.pageSize;
            this.tablelist()
        },
        // 重置
        reset() {
            this.ssearchList = {
                storeCode: '',
                storeName: '',
                submiterCode: '',
                submiterName: '',
                org3name: '',
                startTime: '',
                pageNo: 1
            };
            this.tablelist();
        },

        // 分页
        pagesiezes(data, pageSize) {
            this.ssearchList.pageNo = data;
            this.ssearchList.pageSize = pageSize;
            this.tablelist();
        },
        onChangeStartTime(e, time) {
            this.ssearchList.startTime = time;
        },
        searchClick() {
            this.tablelist();
        }

    }
}